import React from 'react';
import FTI from './FormTextInput';

const AddressForm = ({ dispatch, formState, billing }) => {
  const handleChange = (e) => {
    const { value, name } = e.target;
    dispatch({ field: name, value });
  };

  return (
    <>
      <div className="w-100">
        <div className="flex w-100">
          <FTI
            width="100"
            label="Street Address Line 1"
            name={billing ? 'bAddressLine1' : 'sAddressLine1'}
            value={billing ? formState.bAddressLine1 : formState.sAddressLine1}
            change={handleChange}
          />
        </div>
        <div className="flex w-100">
          <FTI
            width="100"
            label="Street Address Line 2"
            name={billing ? 'bAddressLine2' : 'sAddressLine2'}
            value={billing ? formState.bAddressLine2 : formState.sAddressLine2}
            change={handleChange}
          />
        </div>
        <div className="flex w-100">
          <FTI
            width="50"
            label="City"
            name={billing ? 'bAddressCity' : 'sAddressCity'}
            value={billing ? formState.bAddressCity : formState.sAddressCity}
            change={handleChange}
          />
          <FTI
            width="20"
            label="State"
            name={billing ? 'bAddressState' : 'sAddressState'}
            value={billing ? formState.bAddressState : formState.sAddressState}
            change={handleChange}
          />
          <FTI
            width="30"
            label="Zipcode"
            name={billing ? 'bAddressZip' : 'sAddressZip'}
            value={billing ? formState.bAddressZip : formState.sAddressZip}
            change={handleChange}
          />

        </div>
      </div>
    </>
  );
};

export default AddressForm;
