import React from 'react';
import FTI from './FormTextInput';

const UserInfoForm = ({ dispatch, formState }) => {
  const handleChange = (e) => {
    const { value, name } = e.target;
    dispatch({ field: name, value });
  };

  return (
    <>
      <div className="w-100">
        <div className="flex w-100">
          <FTI
            width="100"
            label="Email"
            name="email"
            value={formState.email}
            change={handleChange}
          />
        </div>
        <div className="flex w-100">
          <FTI
            width="50"
            label="First Name"
            name="firstName"
            value={formState.firstName}
            change={handleChange}
          />
          <FTI
            width="50"
            label="Last Name"
            name="lastName"
            value={formState.lastName}
            change={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default UserInfoForm;
