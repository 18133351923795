export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem('state', serializedState);
  } catch (err) {
    console.log(err);
  }
};

export const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) return undefined;

    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
  }
};

export const formatProductPrice = value => `$${(value / 100).toFixed(2)}`;
