import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSortDown, faTimes } from '@fortawesome/free-solid-svg-icons';

const CheckoutSection = ({ title, children }) => (
  <div className="w-100 mb3">
    <div className="flex justify-between items-center bg-black white pa2 mb3">
      <div className="ml2 ttu">{title}</div>
    </div>
    {children}
  </div>
);

export default CheckoutSection;
