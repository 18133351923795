export default function productsReducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_PRODUCTS_SUCCESS':
      return Object.assign({}, state, {
        isLoaded: true,
        items: action.payload,
      });
    case 'FETCH_PRODUCTS_FAIL':
      return Object.assign({}, state, {
        isLoaded: true,
        error: action.payload,
      });
    default:
      return state;
  }
}
