import React from 'react';

const FeatureCard = ({ copy, imgUrl, message }) => (
  <div className="w=100 flex flex-column items-center pv4">
    <img src={imgUrl} alt={copy} height="48px" width="48px" />
    <div className="f4 lh-copy mt2 fw7 white ttu">{copy}</div>
    {/* <div className="f6 lh-copy mv1 fw4 white">{message}</div> */}
  </div>
);

export default FeatureCard;
