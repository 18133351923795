import React, { useState } from 'react';
import { connect } from 'react-redux';
import firebase, { googleProvider, facebookProvider } from '../../lib/firebase';

const LoginModal = ({ dispatch }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(userName, password)
      .then((res) => {
        setErrorMessage(null);
        dispatch({ type: 'MODAL_CLOSE' });
        console.log(res);
      })
      .catch(error => setErrorMessage(error.message));
  };

  const socialAuth = (provider) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const token = result.credential.accessToken;
        // console.log(token);
        // // The signed-in user info.
        // const user = result.user;
        console.log(result);
        dispatch({ type: 'MODAL_CLOSE' });
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
        // ...
      });
  };

  return (
    <div className="flex flex-column">
      <h2 className="f3 fw7 tc">Welcome Back!</h2>
      <p className="f7 fw5 tc">
        Login to access previous orders and account information
      </p>
      <div className="w-100 mb2">
        <label htmlFor="username" className="f7 fw8 mb1">
          Username
          <input
            id="username"
            className="pa1 ba br1 b--black-10 w-100 mr1"
            style={{ boxSizing: 'border-box' }}
            value={userName}
            onChange={e => setUserName(e.target.value)}
          />
        </label>
      </div>
      <div className="w-100 mb4">
        <label htmlFor="password" className="f7 fw8 mb1">
          Password
          <input
            id="password"
            type="password"
            className="pa1 ba br1 b--black-10 w-100"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
      </div>
      <button
        type="submit"
        className="bg-black white pv2 ph5 pointer w-100 mb2 br1 b--black"
        onClick={() => handleLogin()}
      >
        Login to Your Account
      </button>
      {errorMessage && <p className="red f7 fw7 tc">{errorMessage}</p>}
      <p className="f7 fw5 tc">
        Need an Account?
        <a
          className="ml2"
          onClick={() => dispatch({ type: 'SET_MODAL_TYPE', payload: { type: 'signup' } })
          }
        >
          Sign Up Here!
        </a>
      </p>
      <hr className="w-90 b--black-05 ph3" />
      <button
        type="button"
        className="bg-orange white pv2 ph5 pointer w-100 br1 mv2 b--orange"
        onClick={() => socialAuth(googleProvider)}
      >
        Sign in with Google
      </button>
      <button
        type="button"
        className="bg-blue white pv2 ph5 pointer w-100 br1 mb2 b--blue"
        onClick={() => socialAuth(facebookProvider)}
      >
        Sign in with Facebook
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modals.isOpen,
});
export default connect(mapStateToProps)(LoginModal);
