import * as firebase from 'firebase/app';
import 'firebase/auth';

const prodConfig = {
  apiKey: 'AIzaSyCQWP5fgWxhUbgeIeK6OxB6vkFUwbRzHcU',
  authDomain: 'infinite-soaps-1542990938296.firebaseapp.com',
  databaseURL: 'https://infinite-soaps-1542990938296.firebaseio.com',
  projectId: 'infinite-soaps-1542990938296',
  storageBucket: 'infinite-soaps-1542990938296.appspot.com',
  messagingSenderId: '57738016458',
  appId: '1:57738016458:web:24fa253ebf432b9a',
};

// const devConfig = {
//   apiKey: process.env.REACT_APP_DEV_API_KEY,
//   authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
//   projectId: process.env.REACT_APP_DEV_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_DEV_APP_ID,
// };
const devConfig = {
  apiKey: 'AIzaSyCQWP5fgWxhUbgeIeK6OxB6vkFUwbRzHcU',
  authDomain: 'infinite-soaps-1542990938296.firebaseapp.com',
  databaseURL: 'https://infinite-soaps-1542990938296.firebaseio.com',
  projectId: 'infinite-soaps-1542990938296',
  storageBucket: 'infinite-soaps-1542990938296.appspot.com',
  messagingSenderId: '57738016458',
  appId: '1:57738016458:web:24fa253ebf432b9a',
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

const firebaseSetup = firebase.initializeApp(config);

export default firebaseSetup;
