import React from 'react';
import { faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logo from '../assets/LOGOwText.png';
import NavItem from './NavItem';

const FullNav = ({
  authenticated, currentUser, dispatch,
}) => (
  <div className="flex justify-between pv4">
    <div>
      <Link to="/" className="justify-center items-center">
        <img alt="" height="80" src={logo} />
      </Link>
    </div>
    <div className="flex flex-column mt3 justify-between">
      <div className="flex items-end justify-end">
        {!authenticated && (
          <li
            className="pointer dib black nav-link-small"
            onClick={() => {
              dispatch({
                type: 'SET_MODAL_TYPE',
                payload: { type: 'login' },
              });
            }}
          >
            Login
          </li>
        )}
        {!authenticated && (
          <li
            className="ml5 pointer dib black nav-link-small"
            onClick={() => {
              dispatch({
                type: 'SET_MODAL_TYPE',
                payload: { type: 'signup' },
              });
            }}
          >
            Sign Up
          </li>
        )}
        {authenticated && (
          <NavItem text="Account" link="/account" ficon={faUser} />
        )}
        <NavItem text="Cart" link="/cart" ficon={faShoppingCart} />
      </div>
      <div className="flex mt4 justify-end">
        <NavItem text="Shop" link="/shop" />
        <NavItem text="Retailers" link="/retailers" />
        <NavItem text="About" link="/about" />
        <NavItem text="Contact" link="/contact" />
      </div>
    </div>
  </div>
);

export default FullNav;
