import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import './styles/style.scss';
import * as ROUTES from './Constants/routes';
import Shop from './Pages/Shop';
import Index from './Pages/Index';
import About from './Pages/About';
import LocationContainer from './Containers/LocationContainer';
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Account from './Pages/Account';
// import Contact from './Pages/Contact';
// import Login from './Pages/Login';
import Modal from './Components/Modals/Modal';

const App = ({ authenticated, dispatch }) => {
  const accountRoutes = () => (
    <div>
      {/* <Route
        path={ROUTES.ACCOUNT_ORDERS}
        render={props => <Account {...props} />}
      /> */}
      {/* <Route
        path={ROUTES.ACCOUNT_ORDER}
        render={props => <Account {...props} />}
      /> */}
      <Route path={ROUTES.ACCOUNT} render={() => <Account />} />
    </div>
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      ))
      }
    />
  );

  return (
    <StripeProvider apiKey="pk_test_6DNCe3MU5G5y46cQUMZsTZlf">
      <Router>
        <div className="flex justify-center">
          <div style={{ width: '100vw', maxWidth: '1280px' }}>
            <Modal />
            <Navigation authenticated={authenticated} dispatch={dispatch} />
            <Route exact path={ROUTES.HOME} component={Index} />
            <Route path={ROUTES.SHOP} component={Shop} />
            <Route path={ROUTES.RETAILERS} component={LocationContainer} />
            <Route path={ROUTES.ABOUT} component={About} />
            <Route exact path={ROUTES.CART} component={Cart} />
            <Route exact path={ROUTES.CHECKOUT} component={Checkout} />
            <PrivateRoute path={ROUTES.ACCOUNT} component={accountRoutes} />
            <Footer />
          </div>
        </div>
      </Router>
    </StripeProvider>
  );
};

const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
  currentUser: state.authentication.currentUser,
});

export default connect(mapStateToProps)(App);
