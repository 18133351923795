/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProductShelf from '../Components/ProductShelf';
import { FETCH_SHELF_SUCCESS, FETCH_SHELF_FAIL } from '../Constants/ActionTypes';
// import { fetchProducts } from '../actions/productActions';

class ProductContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    fetch('https://dry-citadel-41408.herokuapp.com/favorites')
      .then(res => res.json())
      .then(
        (result) => {
          dispatch({ type: FETCH_SHELF_SUCCESS, payload: result });
        },
        (error) => {
          dispatch({ type: FETCH_SHELF_FAIL, payload: error });
        },
      );
  }

  render() {
    return (
      <ProductShelf {...this.props} />
    );
  }
}


const mapStateToProps = state => ({
  items: state.shelves.items,
  error: state.shelves.error,
  isLoaded: state.shelves.isLoaded,
});

export default connect(mapStateToProps)(ProductContainer);
