import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // import { faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const BurgerMenuItem = ({
  text, link, ficon, size,
}) => {
  if (ficon) {
    return (
      <li className="dib mh3 mv3" style={size || null}>
        <Link to={link} className="link white">
          <FontAwesomeIcon icon={ficon} />
          {text}
        </Link>
      </li>
    );
  }
  return (
    <li className="dib mh3 mv3" style={size || null}>
      <Link to={link} className="link white">
        {text}
      </Link>
    </li>
  );
};

export default BurgerMenuItem;
