import React from 'react';
import LoadingState from './LoadingState';
import ProductCard from './ProductCard';

const ProductGrid = ({ error, isLoaded, items }) => {
  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }
  if (!isLoaded) {
    return (
      <LoadingState />
    );
  }
  return (
    <div className="flex-column" style={{ flexBasis: '100px' }}>
      {items.map((category, index) => {
        if (category.category_products.length === 0) {
          return null;
        }
        return (
          <div key={category.category}>
            <h2 className="f2 mt5">{category.category}</h2>
            <div className="flex flex-wrap mb4">
              {category.category_products.map(item => (
                <div key={`product-${item.id}`} className="w-25-l w-third-m w-50-sm w-100">
                  <ProductCard productItem={item} />
                </div>
              ))}
            </div>
            {items.length !== index + 1 && <hr />}
          </div>
        );
      })}
    </div>
  );
};

export default ProductGrid;
