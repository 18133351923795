/* eslint-disable import/prefer-default-export */
import { combineReducers } from 'redux';

import cartReducer from './cartReducer';
import productsReducer from './productsReducer';
import shelfReducer from './shelfReducer';
import locationReducer from './locationReducer';
import authReducer from './authReducer';
import modalReducer from './modalReducer';

export const rootReducer = combineReducers({
  products: productsReducer,
  shoppingCart: cartReducer,
  shelves: shelfReducer,
  locations: locationReducer,
  authentication: authReducer,
  modals: modalReducer,
});
