import React from 'react';

const LocationCard = ({ info, setId }) => {
  const {
    location_id: id,
    name,
    // main_description: mainDescription,
    // secondary_description: secondaryDescription,
    // physical_address: physicalAddress,
    image_url: imageUrl,
    // business_website: businessWebsite,
    // specials,
    // days_open: daysOpen,
  } = info;

  const styles = {
    cardBg: {
      backgroundImage: `linear-gradient(transparent,  #222), url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: '50% 80%',
      backgroundColor: 'black',
      backgroundRepeat: 'no-repeat',
      // width: '180px',
      height: '180px',
    },
  };

  return (
    <div className="w-25-l w-third-m w-50-sm w-100 mv3 flex justify-center">
      <div
        className="grow flex justify-center items-center shadow-6 ma2 pa4 br-pill w-100 pointer"
        style={styles.cardBg}
        onClick={() => setId(id)}
      >
        <h1 className="f5 white tc no-underline">{name}</h1>
      </div>
    </div>
  );
};
export default LocationCard;
