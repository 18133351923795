export default function authReducer(state = {}, action) {
  switch (action.type) {
    case 'AUTH_SUCCESS':
      return {
        ...state,
        authenticated: true,
        currentUser: action.payload.user,
      };
    case 'AUTH_FAIL':
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    default:
      return state;
  }
}
