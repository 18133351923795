import { createStore } from 'redux';
import throttle from 'lodash/throttle';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { rootReducer } from '../reducers';
import { loadState, saveState } from '../utils';

export default function configureStore() {
  const persistedState = loadState() || {};

  const store = createStore(
    rootReducer,
    persistedState,
    devToolsEnhancer(),
  );

  // We only want to persist data and not UI state (e.g. query params, loading
  // states, etc.). If you would like to add another slice of state here,
  // please make sure the reducer you would like to include does not contain any
  // UI state. The only exception to this rule is the white label content, which
  // includes a UI theme (e.g. colors) that should be persisted.
  store.subscribe(
    throttle(() => {
      const {
        shoppingCart,
      } = store.getState();

      saveState({
        shoppingCart,
      });
    }, 1000),
  );

  return store;
}
