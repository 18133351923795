import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faShoppingCart,
  faTimes,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import BurgerMenuItem from './BurgerMenuItem';
import '../styles/burgermenu.scss';

const HamburgerMenu = () => (
  <div
    className="flex justify-between items-center"
    style={{ height: '100px' }}
  >
    <div className="mv2">
      <Link to="/cart" className="link black">
        <FontAwesomeIcon icon={faShoppingCart} size="lg" />
      </Link>
    </div>
    <div>
      <Link to="/" className="justify-center">
        <img src={logo} className="ma2" height="80px" width="80px" alt="logo" />
      </Link>
    </div>
    <div style={{ height: '24px', width: '24px' }}>
      <Menu
        right
        width="100%"
        customBurgerIcon={<FontAwesomeIcon icon={faBars} size="lg" />}
        customCrossIcon={<FontAwesomeIcon icon={faTimes} size="lg" color="white" />}
      >
        <BurgerMenuItem text="Home" link="/" />
        <BurgerMenuItem text="Shop" link="/shop" />
        <BurgerMenuItem text="Locations" link="/locations" />
        <BurgerMenuItem text="About" link="/about" />
        <BurgerMenuItem text="Contact" link="/contact" />
        <BurgerMenuItem text=" Account" link="/login" ficon={faUser} />
      </Menu>
    </div>
  </div>
);

export default HamburgerMenu;
