import React from 'react';
import { Link } from 'react-router-dom';

const CTA = ({
  text, link, color, bgColor,
}) => (
  <Link to={link} className={`ba br-pill bw-cta ph4 pv2 mt3 mb3 mr3 nav-link lh-copy ${color} bg-${bgColor} b--${color}`}>
    {text}
  </Link>
);

export default CTA;
