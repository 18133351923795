import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // import { faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FooterItem = ({ text, link, ficon }) => (
  <li className="dib pb3">
    <Link to={link} className="link black hover-white">
      {ficon ? <FontAwesomeIcon icon={ficon} /> : null}
      {text}
    </Link>
  </li>
);

export default FooterItem;
