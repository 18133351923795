import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';

const CardSection = () => (
  <div className="flex">
    <div className="mh1 mv1">
      <div className="mb1 f7 fw7">Card details</div>
      <CardNumberElement
        className="ba br2 form-cl w5"
        style={{ base: { fontSize: '18px' } }}
      />
    </div>
    <div className="mh1 mv1">
      <div className="mb1 f7 fw7">Expiration Date</div>
      <CardExpiryElement
        className="ba br2 form-cl w4"
        style={{ base: { fontSize: '18px' } }}
      />
    </div>
    <div className="mh1 mv1">
      <div className="mb1 f7 fw7">CVC</div>
      <CardCVCElement
        className="ba br2 form-cl w4"
        style={{ base: { fontSize: '18px' } }}
      />
    </div>
  </div>
);

export default CardSection;
