export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const ACCOUNT_ORDERS = '/account/orders';
export const ACCOUNT_ORDER = '/account/orders/:orderid';
export const RETAILERS = '/retailers';
export const SHOP = '/shop';
export const CONTACT = '/contact';
export const CART = '/cart';
export const ABOUT = '/about';
export const CHECKOUT = '/checkout';
