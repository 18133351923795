const initialState = {
  cartProducts: [],
  cartTotal: 0,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_ONE_TO_CART':
      if (
        state.cartProducts
          .map(product => product.id)
          .includes(action.payload.id)
      ) {
        return {
          ...state,
          cartProducts: state.cartProducts.map(product => (action.payload.id === product.id
            ? { ...product, quantity: product.quantity + 1 }
            : { ...product })),
        };
      }
      return {
        ...state,
        cartProducts: [...state.cartProducts, action.payload],
      };
    case 'REMOVE_ONE_FROM_CART':
      return {
        ...state,
        cartProducts: state.cartProducts.map(product => (action.payload.id === product.id
          ? { ...product, quantity: product.quantity - 1 }
          : { ...product })),
      };
    case 'UPDATE_CART':
      return {
        ...state,
        cartProducts: state.cartProducts.map(product => (action.payload.id === product.id
          ? { ...product, quantity: action.payload.quantity }
          : { ...product })),
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartProducts: state.cartProducts.filter(
          product => action.payload.id !== product.id,
        ),
      };
    case 'REMOVE_ALL_FROM_CART':
      return { ...state, cartProducts: [] };
    case 'CALCULATE_CART':
      return {
        ...state,
        cartTotal: state.cartProducts.reduce(
          (acc, product) => acc + product.quantity * product.price,
          0,
        ),
      };
    default:
      return state;
  }
}
