import React from 'react';

import FooterItem from './FooterItem';
import PageSection from './PageSection';

const Navbar = () => (
  <div className="flex bg-black-05 pv4">
    <PageSection>
      <div className="flex flex-column">
        <div className="w-100 flex flex-wrap mb5">
          <div className="w-50 w-25-ns">
            <div className="flex flex-column">
              <h4 className="f5 f4-l fw6 mt0">Quick Links</h4>
              <FooterItem text="Home" link="/" />
              <FooterItem text="Shop" link="/shop" />
              <FooterItem text="Locations" link="/retailers" />
              <FooterItem text="About" link="/about" />
              <FooterItem text="Contact" link="/contact" />
            </div>
          </div>
          <div className="w-50 w-25-ns">
            <p>Place a seperate menu here.</p>
          </div>
          <div className="w-100 w-50-ns pl3-m">
            <div className="mb4 mb0-ns fl w-100">
              <p className="f5 f4-l fw6 mt0">Sign up for our newsletter.</p>
              <div className="flex flex-wrap">
                <input
                  placeholder="Email Address"
                  className="flex-grow-1 w-50-m w-100 f5 input-reset ba b--black-20 br2 pv2 ph4 border-box mr2-m mb0-m mb2"
                />
                <button
                  type="submit"
                  className="input-reset pointer w4-m w-100 flex-shrink-0 bg-black-80 white f5 pv2 ph4 hover-bg-white ba hover-black-70 b--black-70 br2"
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="w-100 mt2">
              <h4 className="f5 f4-l fw6 mt0">Mailing Address</h4>
              <p className="f7 f6-l db black-70">3536 Wynwood Dr. Birmingham, AL 35210</p>
            </div>
          </div>
        </div>
        <div className="w-100">
          <div className="dt dt--fixed w-100">
            <div className="dn dtc-ns v-mid">
              <p className="f7 black-70 dib pr3 mb3">
                Copyright © Infinite Soaps 2019
              </p>
            </div>
            <div className="db dtc-ns black-70 tc tr-ns v-mid flex items-center">
              <a
                href="https://www.facebook.com/"
                className="link dim dib mr3 black-70"
                title="Impossible Labs on Facebook"
              >
                <svg
                  className="db w2 h2"
                  data-icon="facebook"
                  viewBox="0 0 32 32"
                  fill="currentColor"
                >
                  <title>facebook icon</title>
                  <path d="M8 12 L13 12 L13 8 C13 2 17 1 24 2 L24 7 C20 7 19 7 19 10 L19 12 L24 12 L23 18 L19 18 L19 30 L13 30 L13 18 L8 18 z" />
                </svg>
              </a>
              <a
                href="https://instagram.com/infinitesoaps"
                className="link dim dib mr3 black-70"
              >
                <svg
                  className="db w2 h2"
                  data-icon="instagram"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <title>instagram icon</title>
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://medium.com/"
                className="link dim dib mr3 black-70"
                title="Impossible Labs on Medium"
              >
                <svg
                  className="db w2 h2"
                  x="0px"
                  y="0px"
                  viewBox="0 0 290 248.6"
                  fill="currentColor"
                >
                  <g>
                    <path
                      fill="currentColor"
                      className="st0"
                      d="M287.8,46.3L196,0.3c-0.4-0.2-0.9-0.3-1.3-0.3c0,0-0.1,0-0.1,0c-1.1,0-2.2,0.6-2.8,1.5l-56.6,92l63.2,102.7 l90.4-146.9C289.4,48.3,289,46.8,287.8,46.3z"
                    />
                    <polygon
                      fill="currentColor"
                      points="105.2,61.2 105.2,160.3 193.3,204.4 	"
                    />
                    <path
                      fill="currentColor"
                      d="M201,208.2l80.9,40.5c4.4,2.2,8,0,8-5v-180L201,208.2z"
                    />
                    <path
                      fill="currentColor"
                      d="M95.5,46.7L10.7,4.3L5.4,1.7C4.6,1.3,3.8,1.1,3.2,1.1c-0.9,0-1.7,0.4-2.3,1.1C0.3,2.8,0,3.8,0,5v193.4 c0,3.3,2.4,7.2,5.4,8.7l83.3,41.6c1.2,0.6,2.3,0.9,3.3,0.9c2.8,0,4.8-2.2,4.8-5.8V48.7C96.7,47.8,96.2,47.1,95.5,46.7z"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className="db dn-ns">
            <p className="f7 black-70 mt4 tc">
              Copyright © Infinite Soaps 2019
            </p>
          </div>
        </div>
      </div>
    </PageSection>
  </div>
);

export default Navbar;
