/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProductGrid from '../Components/ProductGrid';
import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAIL,
} from '../Constants/ActionTypes';
// import CheckoutForm from '../Components/CheckoutForm';
// import { fetchProducts } from '../actions/productActions';

class ProductContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    fetch('https://dry-citadel-41408.herokuapp.com/products')
      .then(res => res.json())
      .then(
        (result) => {
          dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: result });
        },
        (error) => {
          dispatch({ type: FETCH_PRODUCTS_FAIL, payload: error });
        },
      );
  }

  render() {
    return <ProductGrid {...this.props} />;
  }
}

const mapStateToProps = state => ({
  items: state.products.items,
  error: state.products.error,
  isLoaded: state.products.isLoaded,
});

export default connect(mapStateToProps)(ProductContainer);
