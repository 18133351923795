import React, { useState, useEffect } from 'react';

import LocationCard from '../Components/LocationCard';
import PageSection from '../Components/PageSection';

const Locations = ({ locations }) => {
  const defaultLocation = {
    // business_website: businessWebsite,
    // days_open: daysOpen,
    // hours,
    image_url: '',
    // main_description: mainDescription,
    name: '',
    // physical_address: physicalAddress,
    // secondary_description: secondaryDescription,
    // specials,
    // url,
  };

  const [id, setId] = useState(null);

  const [selectedlocation, setSelectedLocation] = useState(defaultLocation);

  useEffect(() => {
    if (id) {
      const updatedLocation = locations.find(loc => loc.location_id === id);
      setSelectedLocation(updatedLocation);
    }
  }, [id]);

  const {
    business_website: businessWebsite,
    // days_open: daysOpen,
    // hours,
    image_url: imageUrl,
    main_description: mainDescription,
    name,
    physical_address: physicalAddress,
    secondary_description: secondaryDescription,
    // specials,
  } = selectedlocation;

  return (
    <PageSection>
      <h1 className="f1 lh-title tl ">Retailers</h1>
      {id && (
        <div className="w-100 pa2 flex flex-wrap">
          <div
            className="w-50-l w-100 cover br3 flex items-end justify-start pa3"
            style={{
              backgroundImage: `linear-gradient(
              transparent,
              transparent,
              rgba(0, 0, 0, 1)),
              url(${imageUrl})
            `,
              height: '50vh',
              maxHeight: '400px',
              minHeight: '300px',
            }}
          />
          <div className="w-50-l w-100 pl5-l">
            <h1 className="black f2 fw8">{name}</h1>
            <p className="black-50 i f4 fw5">{physicalAddress}</p>
            <p className="black f5 fw7">{mainDescription}</p>
            <p className="black f5 fw5">{secondaryDescription}</p>
            {businessWebsite && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="black f5 fw8"
                href={businessWebsite}
              >
                More Info
              </a>
            )}
          </div>
          <hr className="b--black-025 w-100" />
        </div>
      )}
      <div className="flex flex-wrap justify-between">
        {locations
          && locations.map(location => (
            <LocationCard
              key={location.location_id}
              setId={setId}
              info={location}
            />
          ))}
        <div
          className="w-auto bg-black white h-100 mv4 mh2 flex-grow-1 br-pill"
          style={{ height: '180px' }}
        >
          <div className="h-100 flex flex-column items-center justify-center">
            <h3>Interested in Wholesale?</h3>
            <div className="ph3 pv2 bg-white br-pill">
              <a className="link black" href="/"> Click here to find out more! </a>
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default Locations;
