import React from 'react';

const FormTextInput = ({
  label, name, value, change, width,
}) => (
  <div className={`flex flex-column w-${width} mv1 mr2`}>
    <div htmlFor={name} className="mb1 f7 fw7">
      {label}
    </div>
    <input
      id={name}
      className="form-cl ba br2 w-100"
      name={name}
      type="text"
      value={value}
      onChange={change}
    />
  </div>
);

export default FormTextInput;
