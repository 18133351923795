import React from 'react';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import CheckoutForm from '../Components/CheckoutForm';
import PageSection from '../Components/PageSection';

const Checkout = props => (
  <PageSection>
    <h1 className="f1 lh-title tl mb0">Checkout</h1>
    <Elements>
      <CheckoutForm {...props} />
    </Elements>
  </PageSection>
);

const mapStateToProps = state => ({
  cartProducts: state.shoppingCart.cartProducts,
  cartTotal: state.shoppingCart.cartTotal,
});

export default connect(mapStateToProps)(Checkout);
