import React from 'react';
import Carousel from 'nuka-carousel';
import ProductCard from './ProductCard';

const ProductShelf = ({ error, isLoaded, items }) => {
  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }
  if (!isLoaded) {
    return (
      // <div className="text-center w-100 h-100 justify-center">
      <div className="vh-50 flex justify-center items-center">
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
  return (
    <Carousel
      slidesToShow={4}
      easing="easeSinOut"
      edgeEasing="easeSinOut"
      slidesToScroll={1}
      style={{ height: '300px' }}
    >
      {items.map(item => (
        <ProductCard key={`product-${item.id}`} productItem={item} />
      ))}
    </Carousel>
  );
};

export default ProductShelf;
