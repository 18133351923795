import React, { useReducer, useState } from 'react';
import { injectStripe } from 'react-stripe-elements';
import axios from 'axios';

// import AddressSection from './AddressSection';
import CardSection from './CardSection';
import AddressForm from './AddressForm';

import CheckoutSection from './CheckoutSection';
import OrderSummary from './OrderSummary';
import UserInfoForm from './UserInfoForm';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  bAddressCity: '',
  bAddressLine1: '',
  bAddressLine2: '',
  bAddressState: '',
  bAddressZip: '',
  sAddressCity: '',
  sAddressLine1: '',
  sAddressLine2: '',
  sAddressState: '',
  sAddressZip: '',
};

const CheckoutForm = ({ stripe, cartTotal }) => {
  const reducer = (state, action) => ({
    ...state,
    [action.field]: action.value,
  });

  const [formState, dispatch] = useReducer(reducer, initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddressSame, setAddressSame] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const {
      firstName,
      lastName,
      email,
      bAddressCity,
      bAddressLine1,
      bAddressLine2,
      bAddressState,
      bAddressZip,
      sAddressCity,
      sAddressLine1,
      sAddressLine2,
      sAddressState,
      sAddressZip,
    } = formState;

    const billingAddressData = {
      address_city: bAddressCity || sAddressCity,
      address_line1: bAddressLine1 || sAddressLine1,
      address_line2: bAddressLine2 || sAddressLine2,
      address_state: bAddressState || sAddressState,
      address_zip: bAddressZip || sAddressZip,
    };

    const name = `${firstName} ${lastName}`;
    const { token } = await stripe.createToken({ ...billingAddressData, name });

    const response = await axios({
      method: 'POST',
      url: 'http://localhost:3500/orders',
      data: {
        first_name: firstName,
        last_name: lastName,
        email,
        billing_address: {
          city: bAddressCity || sAddressCity,
          address_1: bAddressLine1 || sAddressLine1,
          address_2: bAddressLine2 || sAddressLine2,
          state: bAddressState || sAddressState,
          zipcode: bAddressZip || sAddressZip,
        },
        shipping_address: {
          city: sAddressCity,
          address_1: sAddressLine1,
          address_2: sAddressLine2,
          state: sAddressState,
          zipcode: sAddressZip,
        },
        stripe_token: token,
        cart_subtotal: cartTotal,
      },
    });

    if (response) {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="flex flex-wrap" onSubmit={submit}>
        <div className="w-two-thirds-m w-100 mt3">
          <CheckoutSection title="Info">
            <div className="fw8 f5 mb2">User Information</div>
            <UserInfoForm formState={formState} dispatch={dispatch} />
          </CheckoutSection>
          <CheckoutSection title="Shipping">
            <div className="fw8 f5 mb2">Shipping Address</div>
            <AddressForm formState={formState} dispatch={dispatch} />
          </CheckoutSection>
          <CheckoutSection title="Payment">
            <div className="fw8 f5 mb2">Billing Address</div>
            <input
              type="checkbox"
              className="mr2 mv3"
              checked={isAddressSame}
              onChange={() => setAddressSame(!isAddressSame)}
            />
            <span>My shipping address is the same as billing.</span>
            {!isAddressSame && (
              <div className="mb3">
                <AddressForm
                  billing
                  formState={formState}
                  dispatch={dispatch}
                />
              </div>
            )}
            <div className="fw8 f5 mb2">Card Information</div>
            <CardSection />
          </CheckoutSection>
        </div>
        <div className="w-third-m w-100 mb3">
          <div className="pl4-m pt3 sticky">
            <OrderSummary subtotal={cartTotal} />
            <button
              className="bg-black white pv2 ph5 bn w-100 ttu b f7"
              type="submit"
            >
              {isSubmitting ? 'Submitting...' : 'Confirm order'}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default injectStripe(CheckoutForm);
