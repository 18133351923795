import React, { useState, useEffect } from 'react';

import firebase from '../lib/firebase';
import FullNav from './FullNav';
import HamburgerMenu from './HamburgerMenu';
import PageSection from './PageSection';
import { AUTH_FAIL, AUTH_SUCCESS } from '../Constants/ActionTypes';

const Navbar = (props) => {
  const initialWindowSize = window.innerWidth;
  const [navToggle, setNavToggle] = useState(initialWindowSize >= 750);
  const { dispatch } = props;

  useEffect(() => {
    const handleResize = () => {
      setNavToggle(window.innerWidth >= 750);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch({
          type: AUTH_SUCCESS,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: AUTH_FAIL,
        });
      }
    });
  }, []);

  return (
    <PageSection>
      {navToggle ? <FullNav {...props} /> : <HamburgerMenu {...props} />}
    </PageSection>
  );
};

export default Navbar;
