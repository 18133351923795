import React from 'react';
import PageSection from '../Components/PageSection';
import AboutSection from '../Components/AboutSection';
import leafybackground from '../assets/leafybackground.jpg';

const About = props => (
  <PageSection>
    <div
      className="w-100 pa6 flex-column br2 mb6"
      style={{
        background: `linear-gradient(
          transparent, 
          rgba(0, 0, 0, 1)
        ), url(${leafybackground})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      }}
    >
      <AboutSection>
        <h4 className="f1 lh-title tl mb2 mt6 white tc mb4">Our Story</h4>
        <hr className="white b--white" />
        <p className="w-auto white lh-copy">
          Hi! Welcome to Infinite Soaps. Our story starts in the summer of 2013.
          While pursuing a degree in Mechanical Engineering at The University of
          Alabama, I returned home for summer break between junior & senior
          year. It was on this break that my Grandmother taught me the process
          of making artisan lye soaps and ignited my interest in making my own
          soap. At the end of that Summer break, I returned to campus and begin
          doing more research on the history of lye-based soaps, the various
          processes and the benefits of the oils used in the soap making
          process.
        </p>
        <p className="w-auto white lh-copy">
          My history with natural soaps also started long before I learned to
          make them. As a teenager, I suffered from acne on my face and
          shoulders and being an athlete didn&apos;t help any. I tried numerous
          treatments and prescription medications, but none helped without
          causing more harm. My mom suggested that I try a more natural
          solution, lye soap. It was something that I could fit into my everyday
          routine (perfect for a teenager) and came with no side-effects.
        </p>
      </AboutSection>
      <AboutSection>
        <h4 className="f1 lh-title tl mb2 mt6 white tc mb4">Our Mission</h4>
        <hr className="white b--white" />
        {/* <p className="w-auto white lh-copy">
          Hi! Welcome to Infinite Soaps. Our story starts in the summer of 2013.
          While pursuing a degree in Mechanical Engineering at The University of
          Alabama, I returned home for summer break between junior & senior
          year. It was on this break that my Grandmother taught me the process
          of making artisan lye soaps and ignited my interest in making my own
          soap. At the end of that Summer break, I returned to campus and begin
          doing more research on the history of lye-based soaps, the various
          processes and the benefits of the oils used in the soap making
          process.
        </p>
        <p className="w-auto white lh-copy">
          My history with natural soaps also started long before I learned to
          make them. As a teenager, I suffered from acne on my face and
          shoulders and being an athlete didn&apos;t help any. I tried numerous
          treatments and prescription medications, but none helped without
          causing more harm. My mom suggested that I try a more natural
          solution, lye soap. It was something that I could fit into my everyday
          routine (perfect for a teenager) and came with no side-effects.
        </p> */}
      </AboutSection>
    </div>
    {/* <AboutSection>
      <h4 className="f1 lh-title tl mb2 mt6 black tc mb4">Our Team</h4>
      <hr className="black b--black" />
      <div className="mt3 flex flex-wrap justify-around">
        <div className="flex flex-column items-center">
          <img src="https://via.placeholder.com/150" alt="" style={{ width: '150px', height: '150px' }} className="flex-grow-0 flex-shrink-0 br-100 bg-black shadow-6" />
          <p>Christopher Funderburk</p>
        </div>
        <div className="flex flex-column items-center">
          <img src="https://via.placeholder.com/150" alt="" style={{ width: '150px', height: '150px' }} className="flex-grow-0 flex-shrink-0 br-100 bg-black shadow-6" />
          <p>Christopher Funderburk</p>
        </div>
        <div className="flex flex-column items-center">
          <img src="https://via.placeholder.com/150" alt="" style={{ width: '150px', height: '150px' }} className="flex-grow-0 flex-shrink-0 br-100 bg-black shadow-6" />
          <p>Christopher Funderburk</p>
        </div>
      </div>
    </AboutSection> */}
  </PageSection>
);
export default About;
