import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import * as modalTypes from './index';

const Modal = ({ isOpen, modalType, dispatch }) => {
  ReactModal.setAppElement(document.getElementById('root'));

  const MODAL_TYPES = {
    login: modalTypes.LoginModal,
    signup: modalTypes.SignUpModal,
  };

  const SpecifiedModal = MODAL_TYPES[modalType];

  const backdropStyle = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 50,
  };

  const modalStyle = {
    backgroundColor: '#fff',
    borderRadius: 5,
    maxWidth: 500,
    bottom: 'auto',
    margin: '0 auto',
    padding: 30,
  };

  if (!isOpen) {
    return null;
  }
  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={() => dispatch({ type: 'MODAL_CLOSE' })}
      contentLabel="Example Modal"
      ariaHideApp={false}
      style={{
        overlay: { ...backdropStyle },
        content: { ...modalStyle },
      }}
    >
      <SpecifiedModal />
    </ReactModal>
  );
};

const mapStateToProps = state => ({
  isOpen: state.modals.isOpen,
  modalType: state.modals.type,
});
export default connect(mapStateToProps)(Modal);
