export default function productsReducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_LOCATION_SUCCESS':
      return {
        ...state,
        isLoaded: true,
        locations: action.payload,
      };
    case 'FETCH_LOCATION_FAIL':
      return {
        ...state,
        isLoaded: true,
        error: action.payload,
      };
    default:
      return state;
  }
}
