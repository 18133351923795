import React from 'react';
import Carousel from 'nuka-carousel';
import CTA from '../Components/CallToAction';
import PageSection from '../Components/PageSection';
import FeatureCard from '../Components/FeatureCard';
import leaf from '../assets/Leaf.png';
import crueltyfree from '../assets/CrueltyFree.png';
import rfa from '../assets/RFA.png';
import vegan from '../assets/Vegan.png';
// import handWashing from '../assets/hand-washing.jpg';
import rainforest from '../assets/rainforest.jpg';
import ShelfContainer from '../Containers/ShelfContainer';


const Home = () => (
  <>
    <Carousel
      // autoplay
      // autoplayInterval={5000}
      dragging={false}
      wrapAround
      disableAnimation
      withoutControls
      style={{ height: '500px' }}
    >
      <div className="flex justify-center items-center">
        <div
          className="h-100 w-100 flex justify-center items-center"
          style={{
            backgroundImage: `url('${rainforest}')`,
            backgroundPosition: '0 70%',
            backgroundSize: 'cover',
            height: '500px',
          }}
        >
          <div className="mb3 w-80 flex flex-column items-start-l items-center justify-center">
            <div className="mt3 f1 f-subheadline-m f-subheadline-l fw800 ttu lh-solid white tl">
              Ethical Skincare?
            </div>
            <div className="mt2 f2-m f2-l f4 fw400 lh-solid white tl">
              You&apos;ve heard this term before but what does it mean?
            </div>
            <div className="flex flex-wrap justify-start mt4">
              <CTA
                text="Learn More"
                link="/shop"
                color="white"
                bgColor="black-50"
              />
              <CTA
                text="Shop Now"
                link="/shop"
                color="white"
                bgColor="black-50"
              />
            </div>
          </div>
        </div>
      </div>
    </Carousel>
    <PageSection bgColor="bg-black-90" bPadding="0">
      <div className="flex flex-wrap">
        <div className="w-25-m w-50 ph2">
          <FeatureCard imgUrl={vegan} copy="Vegan" />
        </div>
        <div className="w-25-m w-50 ph2">
          <FeatureCard imgUrl={crueltyfree} copy="Cruelty Free" />
        </div>
        <div className="w-25-m w-50 ph2">
          <FeatureCard imgUrl={leaf} copy="Natural" />
        </div>
        <div className="w-25-m w-50 ph2">
          <FeatureCard imgUrl={rfa} copy="Sustainable" />
        </div>
      </div>
    </PageSection>
    <ShelfContainer />
  </>
);
export default Home;
