import React from 'react';

const PageSection = ({ bgColor, children, bPadding }) => (
  <div className={`w-100 flex pb${bPadding} ${bgColor}`}>
    <div className="flex-shrink-0" style={{ width: '48px' }} />
    <div className="flex-grow-1">{children}</div>
    <div className="flex-shrink-0" style={{ width: '48px' }} />
  </div>
);

export default PageSection;
