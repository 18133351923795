export default function modalReducer(state = {}, action) {
  switch (action.type) {
    case 'SET_MODAL_TYPE':
      console.log(action.payload);
      return {
        ...state,
        type: action.payload.type,
        isOpen: true,
      };
    case 'MODAL_OPEN':
      return {
        ...state,
        isOpen: true,
      };
    case 'MODAL_CLOSE':
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
