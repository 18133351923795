import React from 'react';
import ProductContainer from '../Containers/ProductContainer';
import PageSection from '../Components/PageSection';

const backgroundImage = {
  background:
    'linear-gradient(to right, #000,transparent), url(https://images.unsplash.com/uploads/1411040661312f50ec8e2/ded9d54e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2534&q=80)',
  height: '15rem',
  backgroundSize: 'cover',
  backgroundPosition: '0% 10%',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
};

const Shop = () => (
  <div>
    <div className="flex items-center" style={backgroundImage}>
      <h1 className="f1 lh-title white ml6">Give the Perfect Gift</h1>
    </div>
    <PageSection>
      <ProductContainer />
    </PageSection>
  </div>
);
export default Shop;
