import React, { Component } from 'react';
import { connect } from 'react-redux';
import Locations from '../Pages/Locations';
import LoadingState from '../Components/LoadingState';

import {
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAIL,
} from '../Constants/ActionTypes';

// import { fetchProducts } from '../actions/productActions';

class LocationContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    fetch('https://dry-citadel-41408.herokuapp.com/locations')
      .then(res => res.json())
      .then(
        (result) => {
          dispatch({ type: FETCH_LOCATION_SUCCESS, payload: result });
        },
        (error) => {
          dispatch({ type: FETCH_LOCATION_FAIL, payload: error });
        },
      );
  }

  render() {
    const { isLoaded, error, locations } = this.props;
    if (!isLoaded) {
      return <LoadingState />;
    }
    if (error) {
      return <div>{JSON.stringify(error)}</div>;
    }
    return (
      <Locations locations={locations} />
    );
  }
}

const mapStateToProps = state => ({
  locations: state.locations.locations,
  error: state.locations.error,
  isLoaded: state.locations.isLoaded,
});

export default connect(mapStateToProps)(LocationContainer);
