import React from 'react';
import { formatProductPrice } from '../utils';

const OrderSummary = ({ subtotal }) => (
  <div className="w-100">
    <div className="f5 b pb1 bb b--black-50">Order Summary</div>
    <div className="bb pv1 mb3 b--black bw1">
      <div className="flex justify-between">
        <h4 className="mv2 f7 fw3">Subtotal:</h4>
        <h4 className="mv2 f7 fw3">{formatProductPrice(subtotal)}</h4>
      </div>
      <div className="flex justify-between">
        <h4 className="mv2 f7 fw3">Shipping:</h4>
        <h4 className="mv2 f7 fw3">TBD</h4>
      </div>
      <div className="flex justify-between">
        <h4 className="mv2 f7 fw3">Tax:</h4>
        <h4 className="mv2 f7 fw3">TBD</h4>
      </div>
      <div className="flex justify-between mb1">
        <h4 className="mv2 f6 b">Order Total:</h4>
        <h4 className="mv2 f6 b">TBD</h4>
      </div>
    </div>
  </div>
);

export default OrderSummary;
