import React, { useState } from 'react';
import firebase from '../lib/firebase';
import PageSection from '../Components/PageSection';

const Account = () => {
  const [userInfo, setUserInfo] = useState(null);

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('signed out');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentUserToken = () => {
    const user = firebase.auth().currentUser;
    // The user's ID, unique to the Firebase project. Do NOT use
    // this value to authenticate with your backend server, if
    // you have one. Use User.getToken() instead.
    console.log({
      user: {
        name: user.displayName,
        email: user.email,
        uid: user.uid,
        userToken: user.getIdToken(),
      },
    });
  };

  return (
    <PageSection>
      <div className="flex flex-column">
        <div className="flex justify-between items-end">
          <h1>My Account</h1>
          <div>
            <button
              type="button"
              className="bg-white black b--black ba pointer ph5 pv2 mb4 br-pill"
              onClick={() => handleSignOut()}
            >
              Sign Out
            </button>
          </div>
        </div>
        <div className="flex flex-wrap w-100 bg-black white pa4-l pa2">
          <div className="w-third-m w-100 br-m bb bb-0-m b--white-70 pa3">
            <h4 className="ttu mt0">Profile</h4>
            <h5 className="f7 fw1 white-90 mb0">Name:</h5>
            <p className="f6 fw6 white mv1">FirstName LastName</p>
            <h5 className="f7 fw1 white-90 mb0">Email:</h5>
            <p className="f6 fw6 white mv1">emailemailemail@email.com</p>
          </div>
          <div className="w-third-m w-100 br-m bb bb-0-m b--white-70 pa3">
            <h4 className="ttu mv0">Addresses</h4>
            <div className="w-100 flex flex-wrap">
              <div className="w-100-m w-50-ns w-100">
                <h5 className="f7 fw1 white-90 mb0">Shipping:</h5>
                <p className="f6 fw6 white mv1">FirstName LastName</p>
                <p className="f6 fw6 white mv1">1234 Saint Olaf Dr.</p>
                <p className="f6 fw6 white mv1">Saint Olaf, Minnesota 90210</p>
              </div>
              <div className="w-100-m w-50-ns w-100">
                <h5 className="f7 fw1 white-90 mb0">Billing:</h5>
                <p className="f6 fw6 white mv1">FirstName LastName</p>
                <p className="f6 fw6 white mv1">1234 Saint Olaf Dr.</p>
                <p className="f6 fw6 white mv1">Saint Olaf, Minnesota 90210</p>
              </div>
            </div>
          </div>
          <div className="w-third-m w-100 pa3">
            <h4 className="ttu mt0">Payment Information</h4>
            <div className="w-100 flex flex-wrap">
              <div className="flex items-center w-100-m w-30-ns w-100">
                <img
                  src="https://via.placeholder.com/40x25.png"
                  alt=""
                  className="br1"
                />
                <h5 className="f7 fw1 white-90 mv0 ml2 ttu">Visa</h5>
              </div>
              <div className="flex items-center w-100-m w-70-ns w-100">
                <h5 className="f7 fw1 white-90 mv0">Ending In:</h5>
                <p className="f6 fw6 white mh1 mv1">4242</p>
                <span>|</span>
                <h5 className="f7 fw1 white-90 mh1 mv0">Expires:</h5>
                <p className="f6 fw6 white mv1">09/2014</p>
              </div>
            </div>
          </div>
        </div>
        <h1>Orders</h1>
        <div className="flex flex-wrap w-100 bg-white black">
          <table className="order-table w-100">
            <thead className="tl ma4">
              <tr className="bg-black white">
                <th className="" style={{ width: '200px' }}>
                  Order Number
                </th>
                <th className="" style={{ width: '150px' }}>
                  Order Date
                </th>
                <th className="" style={{ width: '150px' }}>
                  Ship Date
                </th>
                <th className="">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td>12345</td>
                <td>06/05/2019</td>
                <td>06/05/2019</td>
                <td>Shipped</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr className="mb4 mt6 w-100 b--black" />
        <h1>Subscriptions</h1>
        <div className="flex flex-wrap w-100 bg-white black">
          <table className="order-table w-100">
            <thead className="tl ma4">
              <tr className="bg-black white">
                <th className="" style={{ width: '200px' }}>
                  Order Number
                </th>
                <th className="" style={{ width: '150px' }}>
                  Order Date
                </th>
                <th className="" style={{ width: '150px' }}>
                  Ship Date
                </th>
                <th className="">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td>12345</td>
                <td>06/05/2019</td>
                <td>06/05/2019</td>
                <td>Shipped</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr className="mb4 mt6 w-100 b--black" />
        <h1>Favorites</h1>
        <div className="flex flex-wrap w-100 bg-white black mb6">
          <div>Favorite Products Will Be Here</div>
        </div>
      </div>

      <button
        type="button"
        className="bg-red white pv2 ph5 pointer"
        onClick={() => getCurrentUserToken()}
      >
        User Info
      </button>
    </PageSection>
  );
};

export default Account;
