import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CartItem from '../Components/CartItem';
import PageSection from '../Components/PageSection';
import OrderSummary from '../Components/OrderSummary';

const Cart = ({ cartItems }) => {
  const subtotal = cartItems
    .map(item => item.quantity * item.price)
    .reduce((acc, cur) => acc + cur, 0);

  if (subtotal === 0) {
    return (
      <div className="vh-50">
        <div className="w-100 h-100 flex flex-column justify-center items-center">
          <h1 className="tc">Oh My! Your Cart is Empty!</h1>
          <button
            type="button"
            className="bg-black-70 w-20 pv2 ph3 white hover-black hover-bg-white b--black-70 br2 pointer"
          >
            Check Out Products
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mb5">
      <PageSection>
        <div className="w-100 flex justify-between items-center">
          <h1 className="f1 lh-title tl ">Your Cart</h1>
          <Link to="/checkout" className="link">
            <div className="br2 ba w-100 tc pv2 ph7 hover-bg-black-80 bg-black pointer white">
              Checkout
            </div>
          </Link>
        </div>
        <table className="w-100 cart-table mb3">
          <thead>
            <tr className="bb b--black-50">
              <th className="f5 fw6 tl " />
              <th className="f5 fw6 tl w5 pb3 ph3">Product</th>
              <th className="f5 fw6 tl">Quantity</th>
              <th className="f5 fw6 tl" style={{ width: '100px' }}>
                Unit Price
              </th>
              <th className="f5 fw6 tr" style={{ width: '100px' }}>
                Total Price
              </th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item, index) => (
              <CartItem key={item.id} product={item} index={index} />
            ))}
          </tbody>
        </table>
        <div className="flex justify-end w-100">
          <div className="w-30-l w-100">
            <OrderSummary subtotal={subtotal} />
            <Link to="/checkout" className="link">
              <div className="br2 ba w-100 tc pv2 ph7 hover-bg-black-80 bg-black pointer white">
                Checkout
              </div>
            </Link>
          </div>
        </div>
      </PageSection>
    </div>
  );
};

const mapStateToProps = state => ({
  cartItems: state.shoppingCart.cartProducts,
});

export default connect(mapStateToProps)(Cart);
