export default function shelfReducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_SHELF_SUCCESS':
      return Object.assign({}, state, {
        isLoaded: true,
        items: action.payload,
      });
    case 'FETCH_SHELF_FAIL':
      return Object.assign({}, state, {
        isLoaded: true,
        error: action.payload,
      });
    default:
      return state;
  }
}
