import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // import { faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const NavItem = ({
  text, link, ficon,
}) => {
  if (ficon) {
    return (
      <li className="flex ml5">
        <Link to={link} className="mv0 black nav-link-small">
          <FontAwesomeIcon icon={ficon} className="mr1" />
          {text}
        </Link>
      </li>
    );
  }
  return (
    <li className="flex ml5">
      <Link to={link} className="black nav-link">
        {text}
      </Link>
    </li>
  );
};

export default NavItem;
