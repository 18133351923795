import React from 'react';
import '../styles/style.scss';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { formatProductPrice } from '../utils';

import {
  ADD_ONE_TO_CART,
  REMOVE_ONE_FROM_CART,
  CALCULATE_CART,
} from '../Constants/ActionTypes';

import leaf from '../assets/bunny.svg';
// import RFA from '../Images/bunny.svg';
// import bunny from '../Images/bunny.svg';

const ProductCard = ({
  productItem,
  addCartProducts,
  removeCartProducts,
  cart,
}) => {
  // console.log(productItem);
  const {
    id,
    name,
    category,
    price,
    onSale,
    bogo,
    newproduct,
    natural,
    vegan,
    rainforestCertified,
    imageurl,
    weight,
  } = productItem;
  const cartQty = cart.reduce((qty, product) => {
    if (product.id === id) {
      return product.quantity;
    }
    return qty;
  }, 0);

  return (
    <div className="pv4 ph2 flex-column justify-center">
      <div>
        <div className="w-100 product_card flex justify-center">
          <img className="product-image" src={imageurl} alt="product" />
          <div className="product_type flex flex-column">
            {natural === true ? (
              <img
                className="mv1"
                src={leaf}
                height={`${20}px`}
                alt="Natural"
              />
            ) : null}
            {rainforestCertified === true ? (
              <img className="mv1" src={leaf} height={`${20}px`} alt="RFA" />
            ) : null}
            {vegan === true ? (
              <img className="mv1" src={leaf} height={`${20}px`} alt="vegan" />
            ) : null}
          </div>
          {onSale === true ? (
            <div className="product_sale bg-green white flex items-center justify-center">
              <p className="f7">Sale</p>
            </div>
          ) : null}
          {newproduct === true ? (
            <div className="product_sale bg-yellow black flex items-center justify-center">
              <p className="f7">New</p>
            </div>
          ) : null}
          {bogo === true ? (
            <div className="product_flag bg-red white flex items-center br2 br--right justify-center">
              <p className="f6">BUY ONE GET ONE</p>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div>
          <div className="f5 fw6 mv1">{name}</div>
          {/* <div className="f6 fw3 mv1">{category}</div> */}
        </div>
        <div className="flex mt3 ba br1 b--black-10">
          <div className="w-15 bg-white black pv2 tc flex align-center justify-end">
            <button
              type="button"
              className="f7 mv1 dim bn black bg-transparent pointer"
              aria-label={`Remove 1 ${name} ${category} from cart`}
              onClick={() => {
                removeCartProducts(id, cartQty);
              }}
            >
              <FontAwesomeIcon icon={faMinus} size="1x" />
            </button>
          </div>
          <div className="w-20 bg-white black pv2 tc flex align-center justify-center">
            <h4 className="f7 fw7 mv1 black">{cartQty}</h4>
          </div>
          <div className="w-15 bg-white black pv2 tc flex align-center justify-start br b--black-10">
            <button
              type="button"
              className="f7 mv1 dim bn black bg-transparent pointer"
              aria-label={`Add 1 ${name} ${category} to cart`}
              onClick={() => {
                addCartProducts(productItem);
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" />
            </button>
          </div>
          <div className="w-50 bg-white black pv2 tc flex align-center justify-center">
            <div className="f7 fw7 black mv1">
              <span>{formatProductPrice(price)}</span>
              <span className="f7 fw4"> / </span>
              <span className="f7 fw4">{weight || '12 oz'}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center bg-black white h1" />
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  cart: state.shoppingCart.cartProducts,
});

const mapDispatchToProps = dispatch => ({
  addCartProducts: (item) => {
    dispatch({
      type: ADD_ONE_TO_CART,
      payload: { ...item, quantity: 1 },
    });
    dispatch({
      type: CALCULATE_CART,
    });
  },
  removeCartProducts: (id, cartQty) => {
    if (cartQty !== 0) {
      dispatch({
        type: REMOVE_ONE_FROM_CART,
        payload: {
          id,
        },
      });
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCard);
