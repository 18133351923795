import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import { formatProductPrice } from '../utils';
import '../styles/style.scss';
import { REMOVE_FROM_CART, UPDATE_CART } from '../Constants/ActionTypes';

const CartItem = ({ product, removeCartProduct, updateCartProduct }) => {
  const {
    id, name, price, quantity, imageurl, category,
  } = product;

  const linetotal = price * quantity;

  return (
    <tr className="bt bb b--black-25">
      <td className="fw6 pv3 pr2 w2">
        <FontAwesomeIcon
          className="black-50"
          icon={faTimes}
          onClick={() => {
            removeCartProduct(id);
          }}
        />
      </td>
      <td className="fw6 pv3 pr2">
        <div className="flex">
          <img src={imageurl} className="cart-img mr3" alt="..." />
          <div className="">
            <div className="f5 fw6 mt0 mb1">{name}</div>
            <div className="f7 fw2 mt2 mb2">{category}</div>
          </div>
        </div>
      </td>
      <td className="fw6 pv3 pr2">
        <input
          className="pv3 pr1 pl3 tc f6 b br2 bw0 b--black-10 w3"
          onChange={e => (e.target.value === '0'
            ? removeCartProduct(id)
            : updateCartProduct(id, e.target.value))
          }
          min={0}
          type="number"
          value={quantity}
        />
      </td>
      <td className="fw6 pv3 pr2">{formatProductPrice(price)}</td>
      <td className="fw6 pv3 pr2 tr">{formatProductPrice(linetotal)}</td>
    </tr>
  );
};

const mapDispatchToProps = dispatch => ({
  removeCartProduct: (id) => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: {
        id,
      },
    });
  },
  updateCartProduct: (id, quantity) => {
    dispatch({
      type: UPDATE_CART,
      payload: {
        id,
        quantity,
      },
    });
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(CartItem);
